import ajax from '@/utils/ajax.js'

// 积分商品列表
export function getList(params) {
  return ajax.post('/IntegralShop/getIntegralGoodsList',params)
}


// 获取详情
export function getDetailById(params) {
  return ajax.post('/Coupon/detail',params)
}

/**
 * 新增积分商品
 * @param {*} params 
 * @returns 
 */
export function addIntegralGoods(params) {
  return ajax.post('/IntegralShop/addIntegralGoods',params)
}

/**
 * 获取优惠券列表
 * @param {*} params 
 * @returns 
 */
export function getCouponList(params) {
  return ajax.post('/IntegralShop/getCouponListAddUse',params)
}

/**
 * 修改积分商品
 * @param {*} params 
 * @returns 
 */
 export function updateIntegralGoods(params) {
  return ajax.post('/IntegralShop/updateIntegralGoods',params)
}
/**
 * 删除积分商品
 * @param {*} params 
 * @returns 
 */
 export function deleteIntegralGoods(params) {
  return ajax.post('/IntegralShop/deleteIntegralGoods',params)
}
