export const rowKey = "integral_goods_id";
export const columns = [
  {
    title: "优惠券名称",
    align: "center",
    dataIndex: "coupon_name",
  },
  {
    title: "优惠券内部名",
    align: "center",
    dataIndex: "coupon_name_interior",
  },
  {
    title: "优惠券有效期",
    align: "center",
    dataIndex: "coupon_effective_time_start",
    width: 230,
    slots: {
      customRender: "coupon_effective_time_start",
    },
    slotsType: "all",
    slotsFunc: (val) => {
      return val.coupon_effective_time_start.substr(0,10)+" ~ "+val.coupon_effective_time_end.substr(0,10)
    },
  },
  {
    title: "活动有效期",
    align: "center",
    dataIndex: "start_time",
    width: 230,
    slots: {
      customRender: "start_time",
    },
    slotsType: "all",
    slotsFunc: (val) => {
      return val.start_time.substr(0,10)+" ~ "+val.end_time.substr(0,10)
    },
  },
  {
    title: "需要积分数",
    align: "center",
    dataIndex: "need_integral",
    width: 100
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 180,
    slots: {
      customRender: "operation",
    },
  },
];

export default {
  rowKey,
  columns,
};
