<template>
  <a-modal
    :visible.sync="show"
    title="选择优惠券创建兑换"
    destroyOnClose
    width="800px"
    :footer="null"
    @cancel="$emit('update:show', false)"
  >
    <base-table
      ref="intrgralAddTable"
      rowKey="coupon_id"
      :columnsData="columns"
      :tableData="tableData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #operation="{record}">
        <a-button type="link" @click="handlerSelect(record)">选择</a-button>
      </template>
    </base-table>

    <!-- 新增 兑换 -->
    <a-modal :visible="isShowInput"
             width="500px"
             title="输入积分，创建兑换"
             @ok="handleSave" 
             @cancel="isShowInput = false">
      <a-form-model :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 18 }"
                    :model="formData">
        <a-form-model-item prop="name" class="mb-2" label="兑换优惠券">
          <span>{{formData.coupon_name}}</span>
        </a-form-model-item>
        <a-form-model-item prop="need_integral" class="mb-2" label="需要积分数">
          <a-input-number style="width:100%" placeholder="积分数量" 
              v-model="formData.need_integral" :step="1" />
        </a-form-model-item>
        <a-form-model-item class="mb-2" label="活动有效期" prop="activity_date">
          <a-range-picker style="width: 100%;"
              v-model="activity_date" valueFormat="YYYY-MM-DD" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-modal>
</template>

<script>
import { getCouponList, addIntegralGoods } from "@/api/activity/integral.js"
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    typeId: {
      type: [String, Number],
    },
    selectKeys: {
      type: Array,
    },
  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      searchForm: {
        page: 1,
        page_count: 10,
      },
      formData: {
        coupon_id: "",
        coupon_name: "",
        need_integral: undefined,
      },
      activity_date: [],

      total: 0,
      tableData: [],
      columns: [
        {
          title: "优惠券名称",
          dataIndex: "coupon_name",
          align: "center",
        },
        {
          title: "内部名称",
          dataIndex: "coupon_name_interior",
          align: "center",
        },
        // {
        //   title: "有效期开始",
        //   dataIndex: "start_time",
        //   align: "center",
        // },
        // {
        //   title: "有效期结束",
        //   dataIndex: "end_time",
        //   align: "center",
        // },
        {
          title: "有效期",
          dataIndex: "start_time",
          align: "center",
          width: 220,
          slots: { customRender: 'start_time' },
          slotsType: "all",
          slotsFunc: (val) => {
            return val.start_time.split(" ")[0]+" ~ "+val.end_time.split(" ")[0]
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 70,
          slots: { customRender: 'operation' }
        },
      ],

      isShowInput: false,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { data, code } = await getCouponList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    handlerSelect(item){
      this.formData.coupon_id = item.coupon_id
      this.formData.coupon_name = item.coupon_name
      this.formData.need_integral = 0
      this.isShowInput = true
    },
    async handleSave() {
      this.formData.need_integral = parseInt(this.formData.need_integral) || 0
      if(!(this.formData.need_integral > 0)){
        this.$message.warning("请填写正确积分数")
        return
      }
      
      if(this.activity_date.length == 2){
        this.formData.start_time = this.activity_date[0] + " 00:00:00"
        this.formData.end_time   = this.activity_date[1] + " 23:59:59"
      }else{
        this.$message.warning("请选择活动时间范围")
        return
      }
      
      const { code } = await addIntegralGoods(this.formData)
      if (code === 0) {
        this.isShowInput = false
        this.$message.success("添加成功")
        this.$emit("refreshData")
        this.$emit("update:show", false)
      }
    },
  },
}
</script>

<style>
</style>