<template>
  <div>
    <base-table
      ref="integralTableRef"
      id="integralTableID"
      :columnsData="columns"
      :rowKey="rowKey"
      :tableData="tableData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
      :getListFunc="initData"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">积分兑换</h4>
          <a-button type="primary" @click="handlerAdd" html-type="submit">新建</a-button>
        </div>
        <div class="flex justify-between mr-2" style="margin-left:10px">
          <a-tabs type="card" class="w-full" 
            :activeKey="searchForm.status" @change="handlerStatusChange">
            <a-tab-pane v-for="item in statusList" 
              :key="item.status" :tab="item.name"></a-tab-pane>
          </a-tabs>
        </div>
      </template>

      <template #operation="{ record }">
        <a-button type="link" class="p-0" @click="handlerEdit(record)">修改有效期</a-button>
        <a-button type="link" class="ml-4 p-0" @click="handlerDelete(record)">删除</a-button>
      </template>
    </base-table>

    <!-- 添加 -->
    <IntrgralAdd v-if="isShowAdd" :show.sync="isShowAdd" @refreshData="initData" />

    <!-- 编辑 -->
    <a-modal :visible="isShowEdit"
             width="500px"
             title="修改活动有效期"
             @ok="handlerEditOk" 
             @cancel="isShowEdit = false">
      <a-form-model :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
        <a-form-model-item prop="coupon_name" class="mb-2" label="优惠券">
          <span>{{activeRow.coupon_name}}</span>
        </a-form-model-item>
        <a-form-model-item prop="coupon_name_interior" class="mb-2" label="优惠券内部名">
          <span>{{activeRow.coupon_name_interior}}</span>
        </a-form-model-item>
        <a-form-model-item class="mb-2" label="活动有效期" prop="edit_activity_date">
          <a-range-picker style="width: 100%;"
              v-model="edit_activity_date" valueFormat="YYYY-MM-DD" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

  </div>
</template>

<script>
import pageData from "./columns";
import { getList, updateIntegralGoods, deleteIntegralGoods } from "@/api/activity/integral.js";
import IntrgralAdd from "./components/intrgral-add";
export default {
  components: {
    IntrgralAdd,
  },
  data() {
    return {
      ...pageData,
      isShowAdd: false,
      wrapperCol: { span: 19 },
      labelCol: { span: 5 },
      total: 0,
      tableData: [],

      searchForm: {
        status: 4,
        page: 1,
        page_count: 20,
      },
      // （0-全部，1-过期，2-未到期，3-进行中，4-未到期+进行中）默认0
      statusList: [
        {
          status: 0,
          name: "全部"
        },{
          status: 4,
          name: "未到期+进行中"
        },{
          status: 3,
          name: "进行中"
        },{
          status: 2,
          name: "未到期"
        },{
          status: 1,
          name: "已过期"
        },
      ],

      // 编辑
      activeRow: {},
      edit_activity_date: [],
      isShowEdit: false,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    handlerSearch(){
      this.searchForm.page = 1
      this.initData()
    },
    async initData() {
      const { data, code } = await getList(this.searchForm);
      if (code === 0) {
        this.tableData = data.list;
        this.total = data.total_count;
      }
    },
    handlerStatusChange(value){
      this.searchForm.status = value
      this.handlerSearch()
    },
    resetForm() {
      this.$refs.searchRef.resetFields();
      this.handlerSearch();
    },
    handlerAdd() {
      this.isShowAdd = true;
    },
    handlerEdit(record){
      this.activeRow = record
      if(record.start_time){
        this.edit_activity_date = [record.start_time.substr(0,10), record.end_time.substr(0,10)]
      }else{
        this.edit_activity_date = []
      }
      this.isShowEdit = true
    },
    async handlerEditOk(){
      const params = {
        integral_goods_id: this.activeRow.integral_goods_id
      }
      if(this.edit_activity_date.length == 2){
        params.start_time = this.edit_activity_date[0] + " 00:00:00"
        params.end_time   = this.edit_activity_date[1] + " 23:59:59"
      }else{
        this.$message.info('请选择有效期')
        return
      }
      
      const { code, message } = await updateIntegralGoods(params)
      if (code == 0) {
        this.$message.success("已修改");
        this.isShowEdit = false;
        this.initData();
      } else {
        this.$message.error(message);
      }
    },
    async handlerDelete(e) {
      let that = this;
      this.$confirm({
        title: "确认要删除吗?",
        async onOk() {
          const { code, message } = await deleteIntegralGoods({
            integral_goods_id: e.integral_goods_id,
          });
          if (code == 0) {
            that.$message.success("删除成功");
            that.initData();
          } else {
            that.$message.error(message);
          }
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
  },
};
</script>

<style></style>
